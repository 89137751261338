document.addEventListener('turbolinks:load', () => {
  const signinForm = document.getElementById('signin_form')
  const signinSubmit = document.getElementById('signin_submit')
  const username = document.getElementById('username')
  const password = document.getElementById('password')

  const isFormComplete = () => {
    const disabled = (username.value.trim() == '' || password.value.trim() == '')
    signinSubmit.disabled = disabled
    if (disabled) {
      signinSubmit.classList.add('opacity-50')
      signinSubmit.classList.add('text-gray-500')
      signinSubmit.classList.remove('text-white')
    } else {
      signinSubmit.classList.remove('opacity-50')
      signinSubmit.classList.remove('text-gray-500')
      signinSubmit.classList.add('text-white')
    }
    
  }

  signinSubmit.addEventListener('click', () => {
    signinForm.submit()
  })

  username.addEventListener('input', (event) => {
    isFormComplete()
  })

  password.addEventListener('input', (event) => {
    isFormComplete()
  })

  const signinContainer = document.getElementById('signin_container')
  const signInLink = document.getElementById('signin_link')
  if (signInLink !== null) {
    signInLink.addEventListener('click', () => {
      signinContainer.classList.remove('hidden')
    })
  }

  const signInClose = document.getElementById('signin_close')
  signInClose.addEventListener('click', () => {
    signinContainer.classList.add('hidden')
  })
})